.shopping-list {
  width: 100%;
  margin-top: 20px;
  ul {
    list-style: none;
  }
  .shopping-edit {
    display: flex;
    justify-content: space-between;
    .icon {
      margin: 0px 0px 0px auto;
    }
    .alert {
      width: 100%;
      margin-right: 15px;
    }
    .lt-btn-outline {
      width: 100%;
      max-width: 220px;
      padding: 0px;
    }
  }
  .category.edit {
    h3 {
      .fa-plus {
        color: $white;
        &:hover {
          color: $light-gray;
        }
      }
    }
    ul {
      padding: 0px;
      padding: 5px;
      li {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin-bottom: 20px;
        span {
          flex: 1;
          margin-right: 5px;
          &.whole,
          &.upper,
          &.lower {
            max-width: 40px;
          }
          &:last-child {
            margin-right: 0px;
          }
        }
        .select-fraction {
          width: 100%;
          max-width: 90px;
          margin-right: 5px;
          padding-bottom: 0px;
          label {
            display: none;
          }
          .select {
            .inner__placeholder {
              color: $black;
            }
            .inner__control {
              background: $white;
              color: $black;
              padding: 0px;
              font-size: 12px;
              border: $border;
            }
          }
        }
        input {
          width: 100%;
          box-sizing: border-box;
          border: $border;
        }
      }
    }
    .fa-times-circle:hover {
      color: lighten($brand-danger, 15);
    }
  }
}
.shoppinglist-body {
  .categories {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -10px;
    .category {
      border: $border;
      flex: 1 1 380px;
      margin: 10px;
      h3 {
        background-color: $brand-secondary;
      }
      .fraction {
        margin: -4px -3px;
      }
      ul {
        list-style: none;
      }
    }
  }
}
