.mealtemplate{
  flex: 1;
}
.templates-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.template{
  flex: 1 1 500px;
  margin: 15px;
  border: $border;
  position: relative;
  .template-title{
    display: flex;
    justify-content: center;
    position: relative;
    background: $brand-primary;
    color: $white;
    font-size: 28px;
    padding: 15px;
    font-weight: 100;
    text-align: center;
    .title__buttons{
      right: 15px;
    }
    .btn.right,
    .btn.close{
      float: none;
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .delete.white {
      color: #fff;
      line-height: 1;
    }
  }
  .title__buttons{
    position: absolute;
    right: 0px;
    svg{
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .number{
    display: flex;
    position: relative;
    font-size: 26px;
    margin: 25px 15px 0px;
    .date {
      display: inline-block;
      margin-left: 10px;
      font-weight: 100;
    }
    button{
      position: relative;
      top: -5px;
    }
  }
  .template-description {
      font-size: 16px;
      padding: 15px 10px 0px;
  }
  .add-day {
    text-align: center;
    margin: 15px 0px;
  }
  .meals {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .recipe{
      font-weight: 100;
      margin-bottom: 5px;
      font-size: 18px;
    }
  }
  .delete {
    border: none;
    font-size: 30px;
    background: transparent;
    float: right;
    display: block;
    cursor: pointer;
  }
}

.create-mealplan{
  padding: 15px 0px;
  border-bottom: $border;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  button.btn{
    width: 100%;
    margin: 10px 0px;
    @media (min-width: 1000px) {
      width: auto;
      min-width: 160px;
      min-height: 63px;
      margin: 0px;
      &.spacer {
        margin: 0px 15px;
      }
    }
  }
  .lt-form-element{
    width: 100%;
    padding-bottom: 25px;
    flex: 1 1 300px;
    @media (min-width: 600px){
      padding-bottom: 0px;
      margin-right: 15px;
    }
  }
}

.create-template{
  margin: 15px 0px;
  padding: 15px;
  button{
    min-width: 160px;
  }
  .lt-form-element{
    width: 100%;
    padding-bottom: 40px;
  }
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_inner {
  position: absolute;
  margin: auto;
  background: white;
  padding: 30px;
  overflow: scroll;
  .season {
    height: 30px;
  }
  .lt-form-element {
    padding: 15px 0px 0px;
    label {
      display: none;
    }
  }
  ul.recipe-list {
    list-style-type: none;
    padding-left: 0px;
    max-height: 500px;
    display: block;
    overflow: scroll;
    margin-left: 0px;
    .meal-header {
      border-bottom: none;
    }
    button {
      padding: 10px 15px;
    }
    li{
      margin: 5px 0px;
      label{
        cursor: pointer;
      }
    }
  }
  h1{
    font-weight: normal;
    margin-top: 0px;
    font-size: 24px;
    margin-right: 60px;
    color: $black;
  }
  .close{
    float: none;
    position: absolute;
    right: 30px;
    top: 24px;
  }
}

.day-count {
  display: inline-block;
  margin: 10px;
}
