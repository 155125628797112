.calendar {
  width: 100%;
  .header-date-step {
    display: flex;
    align-items: center;
    margin: 0;
    text-align: center;
    font-family: 'Roboto' sans-serif;
    background: $brand-primary;
    color: $white;
    font-weight: 100;
    min-height: 70px;
    button {
      padding: 10px 20px;
      margin-left: 15px;
      background-color: $brand-primary;
      border: 1px solid $brand-border;
      color: $white;
      text-decoration: none;
      border-radius: 4px;
      font-size: 25px;
      max-height: 50px;
      &:hover {
        background-color: $brand-secondary;
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .header-calendar-picker {
    display: flex;
    margin: 0;
    align-items: center;
    text-align: center;
    font-family: 'Roboto' sans-serif;
    background: $brand-primary;
    color: $white;
    padding: 0px 15px;
    font-weight: 100;
    min-height: 70px;
    float: right;
    @media (max-width: 1200px) {
      float: none;
    }

    h2 {
      display: flex;
      margin: auto;
    }

    button {
      padding: 10px 20px;
      display: flex;
      background-color: $brand-primary;
      color: $white;
      text-decoration: none;
      font-size: 20px;
      font-weight: lighter;
      border-radius: 4px;
      max-height: 50px;
      margin-right: 3px;
      @media (max-width: 500px) {
        padding: 10px 13px;
        font-size: 13px;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:hover {
        background-color: $brand-secondary;
        cursor: pointer;
      }
    }
    &_active {
      border: 1px solid white;
    }
    &_inactive {
      border: none;
      .toggle-edit {
        font-size: 20px;
        width: 25px;
        margin-right: -3px;
      }
    }
  }
  .mutation {
    height: 40px;
  }
  .meals-body {
    padding: 20px;
    flex: 1 1 500px;

    .meal {
      border: 1px solid $brand-border;
      margin-bottom: 10px;
      padding: 15px;

      .meal-title {
        font-size: 18px;
        color: $brand-secondary;
      }
      .add-recipes{
        font-size: 20px;
        margin-bottom: 2px;
        .exclamation {
          color: $brand-warning;
          font-size: 0.75em;
          padding: 1px;
          margin-right: 3px;
          margin-bottom: 1px;
        }
        .add-more {
          border: none;
          float: right;
          background: none;
          font-size: 20px;
          line-height: 1;
          cursor: pointer;
          &:hover{
            color: $brand-primary;
          }
        }
      }

      .type {
        font-size: 20px;
        margin-bottom: 5px;
      }
      .recipes {
        font-weight: 100;
        margin-bottom: 5px;
        font-size: 18px;
        .recipe {
          text-decoration: none;
          color: inherit;
        }
        .exclamation {
          color: $brand-warning;
          font-size: 0.8em;
          padding: 1px;
          margin: 0px 3px;
        }
      }
    }
  }

  .meals-week {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &-labels {
      width: 100%;
      display: flex;
      background-color: $brand-primary;
    }
    &__header {
      flex: 1;
      height: 44px;
      border-top: 1px solid white;
      display: flex;
      &-content {
        align-self: center;
        width: 100%;
        color: white;
        font-size: 25px;
        font-weight: 200;
        text-align: center;
      }
    }
  }
  .meals-calendar-day {
    min-width: calc(14.285% - 22px);
    border: 1px solid $brand-border;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    &__button {
      float: right;
      border: none;
      background: none;
      text-decoration: none;
      cursor: pointer;
    }
    &__day-button {
      float: right;
      margin-top: 10px;
      margin-right: 20px;
      font-size: 30px;
      border: none;
      background: none;
      text-decoration: none;
      cursor: pointer;
    }
    &_clickable:hover {

    }
    &__number {
      font-size: 16px;
      padding: 5px;
      &:hover {
        cursor: pointer;
        background-color: $brand-secondary
      }
    }
    &__meal {
      text-align: left;
      margin-bottom: 10px;
      padding: 5px;
      width: 100%;
      .meal-title {
        margin-bottom: 3px;
        font-size: 15px;
        color: $brand-secondary;
      }
      .add-recipes{
        font-size: 16px;
        margin-bottom: 1px;
        .add-more {
          border: none;
          float: right;
          background: none;
          line-height: 1;
          cursor: pointer;
          &:hover{
            color: $brand-primary;
          }
          .small {
            font-size: 15px;
          }
        }
      }
      .recipes {
        font-weight: 100;
        margin-bottom: 5px;
        font-size: 14px;
        .recipe {
          text-decoration: none;
          color: inherit;
        }
        .exclamation {
          color: $brand-warning;
          font-size: 0.8em;
          padding: 1px;
          margin: 0px 3px;
        }
      }
    }
    &__type {
      font-size: 16px;
      margin-bottom: 5px;
    }
    &_faded {
      color: #aaa;
    }
    &_selected {
      background-color: $brand-secondary-faded;
    }
  }
}
