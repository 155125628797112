.select {
  .inner__control {
    background: $brand-border;
    border: none;
    padding: 11px;
    font-size: 24px;
    color: $black;
    font-weight: 100;
  }
  .inner__indicator svg {
    color: black;
  }
  .inner__indicator-separator{
    display: none;
  }
}
