.staff-dashboard {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    color: $brand-primary;
  }

  .select {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: -10px;
  }

  .container {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    .user {

    }
    .details {
      font-size: 0.65em;
      color: $brand-primary;
      font-weight: 200;
    }
  }

  .column {
    width: 100%;
    flex: 1 1 400px;
  }

  .list li:last-child {
    border-bottom: 0px;
  }

  .list-wrapper {
    margin: 15px;
    min-height: 300px;
    overflow: auto;
  }
  .full-width{
    width: 100%;
    display: flex;
    border: 1px solid $brand-border;
    flex-wrap: wrap;
    margin: 0px 15px;
    a.dash-link {
        text-decoration: none;
        font-size: 20px;
        font-weight: 100;
        color: $black;
        padding: 20px;
        text-align: center;
        margin: 15px;
    }
  }

  button {
    display: flex;
    border: 0px solid $brand-border;
    color: $brand-primary;
    text-decoration: none;
    border-radius: 4px;
    font-size: 20px;
  }

  button:hover {
    cursor: pointer;
    color: $brand-border;
  }
}
