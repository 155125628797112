
// Fonts
// TODO: Added these fonts to index.html becuase it wasn't actually importing from the css. Figure out why.
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

// Utils
@import "utils/all";

// Vendor
@import "vendor/burger-menu";

// Base
@import "base/general";

// Components
@import "components/icons/all";
@import "components/btns/all";
@import "components/loaders/all";
@import "components/forms/all";
@import "components/lists";
@import "components/meal-template";
@import "components/allergen";
@import "components/popup";
@import "components/recipe";
@import "components/calendar";
@import "components/shopping-list";

// Layouts
@import "layouts/base";
@import "layouts/login";
@import "layouts/staff";
@import "layouts/user";
@import "layouts/profile";
@import "layouts/family";
@import "layouts/mealplans";
