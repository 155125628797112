.fade-appear {
  opacity: 0;
  // transform: scale(0.9);
}

.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1000ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 1000ms;
}

.slide-enter {
  position: relative;
  left: (833px * 2);
  opacity: 0;
}

.slide-enter-active {
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 500ms ease-out;
}

.slide-exit {
  position: relative;
  left: 0;
  opacity: 1;
}

.slide-exit-active {
  position: relative;
  left: (-833px * 2);
  opacity: 0;
  transition: all 500ms ease-in;
}

.page-enter {
  // opacity: 0;
  transform: translateX(100vw);
}

.page-enter-active {
  // opacity: 1;
  transform: translateX(0);
  transition: all 1000ms ease-out;
}

.page-exit {
  // opacity: 1;
  transform: translateX(0);
}

.page-exit-active {
  // opacity: 0;
  transform: translateX(100vw);
  transition: all 1000ms ease-in;
}
