html,
body,
.dashboard-container {
  background: #fafafa;
}

.topbar {
  border-bottom: 1px solid $brand-border;
  background: #fafafa;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  .logged-in-logo {
    display: none;
    height: 40px;
      @media (min-width: 600px){
        display: block;
      }
    }
  .logged-in-mobile-logo {
    max-width: 150px;
    margin-top: 2px;
    margin-left: 12px;
    @media (min-width: 600px){
      display: none;
    }
  }
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 8px 10px 15px 8px;
}
.staff-dashboard button.tag,
.tag {
    margin: 7px;
    color: $brand-primary;
    padding: 0px;
    &:focus {
      outline-color: transparent;
    }
    &:hover {
      color: $black;
    }
    .not-selected, .selected {
      background: $light-gray;
      font-size: 13px;
      border-radius: 5px;
      padding: 7px;
    }
    .selected {
      background-color: $brand-secondary;
      color: $black;
    }
}
