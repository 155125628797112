.lt-form-element {
  padding-bottom: 40px;
  text-align: left;
  position: relative;

  &.search {
    padding: 0px;
    label {
        margin: 0px;
    }
    textarea {
      border-radius: 0px;
      font-size: 16px;
      padding: 10px 20px;
      font-weight: normal;
      background: $white;
      border: none;
      border-bottom: solid 1px $brand-border
    }
  }

  label {
    font-size: 19px;
    line-height: 19px;
    margin-bottom: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    padding-left: $padding-default;
    color: #8E8E93;
    display: flex;
    justify-content: space-between;
  }

  textarea {
    border: $border;
    border-radius: 5px;
    background: $brand-border;
    min-width: 100%;
    padding: 16px $padding-default;
    font-size: 24px;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #000;
    outline: none !important;
    box-sizing: border-box;
    resize: vertical;
    height: 100px;
  }

  .textarea-error {
    color: $brand-danger;
    position: absolute;
    top: 100px;

    @media screen and (min-width: 530px) {
      position: static;
    }
  }

  &.error {

    textarea {
      border: 2px solid $brand-danger;
    }
  }

}
