.family-header {
  margin: 35px 35px 15px;
  .family-header_inner {
    @media (min-width: 600px){
      display: flex;
      justify-content: space-between;
    }
  }
  .link {
    margin: 25px 0px 35px;
    @media (min-width: 600px){
      margin: 20px 0px 15px;
    }
  }
  h2 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: 300;
  }
}
