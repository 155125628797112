.lt-form-element {
  padding-bottom: 40px;
  text-align: left;
  position: relative;

  &.search {
    padding: 0px;
    label {
        margin: 0px;
    }
    input {
      border-radius: 0px;
      font-size: 16px;
      padding: 10px 20px;
      font-weight: normal;
      background: $white;
      border: none;
      border-bottom: solid 1px $brand-border
    }
  }

  label {
    font-size: 19px;
    line-height: 19px;
    margin-bottom: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    padding-left: $padding-default;
    color: #8E8E93;
    display: flex;
    justify-content: space-between;
  }

  input {
    border: $border;
    border-radius: 5px;
    background: $brand-border;
    width: 100%;
    padding: 16px $padding-default;
    font-size: 24px;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #000;
    outline: none !important;
    box-sizing: border-box;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 40px $brand-border inset !important;
    -webkit-text-fill-color: black !important;
    font-size: 24px;
    line-height: 24px;
  }

  .input-error {
    color: $brand-danger;
    position: absolute;
    top: 100px;

    @media screen and (min-width: 530px) {
      position: static;
    }
  }

  &.error {

    input {
      border: 2px solid $brand-danger;
    }
  }
  &.radio,
  &.checkbox{
    padding: 0px;
    width: 100%;
    input {
      display: none;
    }

    label{
      padding-left: 0px;
      display: block;
      font-size: 16px;
      color: $black;
      margin-bottom: 10px;
      &:before {
        content:'';
        background-color: transparent;
        border: 2px solid $brand-primary;
        padding: 7px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
        top: -2px;
      }
      &:hover:before{
        background: $brand-primary;
        opacity: .4;
      }
      &.true {
        &:before {
          background: $brand-primary;
        }
        &:hover:before{
          opacity: 1;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: 7px;
          width: 3px;
          height: 9px;
          border: solid $white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }

    &.true {
      label {
        &:before {
          background: $brand-primary;
        }
        &:hover:before{
          opacity: 1;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: 7px;
          width: 3px;
          height: 9px;
          border: solid $white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
  &.radio {
    label {
      &:before {
        border-radius: 50%;
      }
      &:after {
        content: none !important;
      }
    }
  }
}
