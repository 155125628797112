.popup-overlay {
  color: $black;
  font-weight: normal;
  text-align: left;
  font-size: 16px;
  .fa-times{
    cursor: pointer;
  }
  .popup-content{
    padding: 15px !important;
  }
  .modal--delete-content {
    max-width: 510px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    border: none;
    color: $black;
    padding: 0px;
    border-bottom: $border;
    padding-bottom: 10px;
    h3{
      font-weight: normal;
      margin: 0px;
    }
  }
  .content{
    margin: 30px 0px;
    padding-bottom: 30px;
    border-bottom: $border;
  }
  .popup__content--blue{
    color: $brand-primary;
  }
  .buttons{
    display: flex;
    justify-content: flex-end;
    button{
      margin-left: 10px;
    }
  }
}
