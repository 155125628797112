.header {
  padding: 20px;
  border: 1px solid $brand-border;
  color: $brand-primary;
  width: 100%;
  font-family: 'Roboto' sans-serif;

  .bold {
    font-weight: bold;
  }

  p.hover {
    cursor: pointer;
  }
}

.profile-header {
  margin: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div div {
    margin-bottom: 5px;
  }
  h2 {
    margin-bottom: 5px;
    font-weight: 300;
  }
  &_loading {
    position: relative;
    display: inline-block;
    min-width: 320px;
    min-height: 85px;
    padding-top: 15px;
  }
  .profile-pic-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .profile-header__image {
    max-width: 200px;
    width: 100%;
    margin-right: 25px;
  }
}

.profile {
  padding: 20px;
  display: flex;
  @media (max-width: 900px){
    display: block;
  }
  .family-template {
    width: 100%;
  }
  .allergens-header a.edit-allergens {
    padding: 0px;
    border: none;
    &:hover {
      background: transparent;
    }
  }
  svg {
    &.fa-edit,
    &.fa-file-pdf,
    &.fa-check {
      margin-left: auto;
      margin-right: 5px;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .mealplans {
    max-width: 370px;
    width: 100%;
  }
  .plans{
    width: 100%;
  }
  .empty {
    padding-top: 0px;
    font-weight: 400;
    color: $brand-primary;
    text-align: left;
    color: $black;
    padding-left: 20px;
  }

  .family-plans {
    padding: 20px;

    .plan-button {
      float: left;
      width: 300px;
      margin: 0px 10px 10px 0px;
    }
  }

  > div {
    h2 {
      margin: 0;
      text-align: center;
      font-family: 'Roboto' sans-serif;
      padding: 20px;
      font-weight: 100;
    }

    h3 {
      margin: 0;
      text-align: center;
      font-family: 'Roboto' sans-serif;
      padding: 10px;
      color: $white;
      font-weight: 75;
    }
  }
  .header-date-step h2{
    @media (max-width: 600px){
      display: none;
    }
  }
}

.family-members {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .member {
    display: inline-block;
    margin-right: 15px;
     a {
        color: $brand-primary;
        background: $light-gray;
        font-size: 13px;
        border-radius: 5px;
        padding: 7px;
        text-decoration: none;
        &:hover {
          color: $black;
        }
      }
    }
  }
