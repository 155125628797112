.lt-navigator {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > * {

    margin-top: 20px;

    @media screen and (min-width: 530px) {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

  }

  @for $i from 1 through 10 {
    .flex-#{$i} {
      flex: $i;
    }
  }
}
