/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $brand-info;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $brand-primary;
  padding-top: 30px;
  font-size: 1.15em;
}

.bm-logo {
  width: 160px;
  margin: auto;
  padding-bottom: 30px;
  outline: none;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  // padding: 0.8em;
}

/* Individual item */
a.bm-item {
  display: inline-block !important;
  color: $white;
  text-decoration: none;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  letter-spacing: .1rem;
  border-top: 1px solid $brand-border;
  pointer: cursor;
  position: relative;



  &.selected {
    // background: $white;
    // color: $brand-primary;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 23px;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-left: 10px solid #fafafa;
      border-bottom: 8px solid transparent;
    }
  }


  &.logout {
    position: absolute;
    bottom: 0;
    left: 0;
    background: $brand-secondary;
    border: none;
  }

  svg {
    padding-right: 20px;
  }

}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
