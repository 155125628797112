
.list-wrapper {
  border: 1px solid $brand-border;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 20px;
  position: relative;
  
  h2 {
    font-weight: 100;
    color: $white;
    background: $brand-primary;
    margin: 0;
    padding: 20px;
    a {
      float: right;
      color: $white;
    }
  }
}
.list {
  padding: 0 20px 20px;
  list-style: none;
  overflow: auto;
  height: calc(100% - 40px);
  box-sizing: border-box;
  margin: 0;
  width: 100%;

  li {
    padding: 20px 0;
    border-bottom: 1px solid $brand-border;
    display: flex;
    justify-content: space-between;
    a {
      color: $black;
      text-decoration: none;
      width: 100%;
      .staff{
        float: right;
      }
    }
  }
}
