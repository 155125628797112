.list-wrapper.recipes-page {
  border: none;
}
.recipe-filters {
    max-width: 550px;
}
.recipe-list{
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  width: auto;
  margin-left: -15px;
  padding-bottom: 15px;
  .meal-header {
    text-align: left;
    border-bottom: 2px solid $light-gray;
    padding-bottom: 2px;
    margin-bottom: 3px;
  }
  .meal-recipes {
    text-align: left;
    font-size: 12px;
  }
  .recipe-wrapper {
    text-decoration: none;
    border: $border;
    flex: 1 1 320px;
    margin: 0px 0px 15px 15px;
  }
  .recipe-info {
    margin: 15px;
  }
  .thumbnail-image {
    height: 180px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    &.placeholder {
      background-size: 200px;
    }
  }
  .thumbnail-name {
    width: 100%;
    overflow-wrap: normal;
    padding: 5px 0px;
    font-size: 20px;
    font-weight: normal;
    color: $black;
  }
  .thumbnail-rating {
    align-self: flex-end;
  }
}
.single-recipe {
  max-width: 900px;
  text-align: center;
  padding: 0px 15px;
  margin: 30px auto;
  .light {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
  }

  .bold {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
  }

  .step {
    position: relative;
    bottom: 0;
    right: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    border-radius: 50%;
    border: 1px solid #7F7F7F;
  }

  .wrapper {
      max-width: 900px;
      text-align: center;
      margin: 30px 40px 60px;
  }

  .section.recipe-header {
    position: relative;
  }
  .title-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
  .section .title {
    text-align: left;
    font-size: 1.5em;
  }

  hr {
    padding-top: 0px;
    padding-bottom: 6px;
    border: none;
    border-bottom: 2px solid #C9C9C9;
    width: 100%;
  }

  .section .title .fail {
    color: #D30000;
  }

  .sub-section {
    text-align: left;
  }

  .sub-section .recipe-section-header {
    color: #7F7F7F;
    border-bottom: 1px solid #C9C9C9;
    text-align: left;
    margin-top: 25px;
    padding-bottom: 3px;
    font-size: 16px;
  }

  .sub-section .body > div:first-child:not(.col) {
    margin-top: 3px;
  }

  .instruction {
    padding: 2px;
    padding-right: 15px;
    display: inline-block;
  }

  .center-cropped {
    margin-top: 10px;
    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    height: 400px;
    width: 100%;
  }
  .submitted-by {
    display: inline-block;
  }
  .description,
  .submitted-by {
    margin-top: 5px;
    font-size: 12px;
    color: #7F7F7F;
  }

  .description{
    max-width: 90%;
  }

  .instructions {
    list-style-type: none;
    font-size: 13px;
  }

  .info-section {
    margin-top: 7px;
    text-align: left;
  }

  .info {
    height: 40px;
    border-left: 3px solid #C9C9C9;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  .season {
    margin: auto;
    padding: 5px;
    width: 30px;
    height: 30px;
  }

  .info-top {
    color: #555555;
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
  }

  .info-bottom {
    color: black;
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    float: right;
  }

  .info-right {
    float: right;
  }

  .general-info {
      margin-bottom: 25px;
  }

  .first {
    border: none;
  }

  .last {
    border-right: none;
  }

  .ingredients ul {
    padding-top: 5px;
    list-style-type: none;
    display: inline-block;
    width: 100%;
    font-size: 13px;
  }

  .ingredients ul li {
    display: flex;
    width: 50%;
    float: left;
    padding-top: 3px;
    font-family: 'Open Sans', sans-serif;
  }

  .measurement {
    float: left;
    width: 20%;
    min-width: 100px;
    text-align: right;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    padding-right: 10px;
    display: inline-block;
  }

  .ingredient {
    text-align: left;
    word-wrap: break-word;
    float: left;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    display: flex;
  }

  .half {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .rating {
    float: right;
    font-size: 0.75em;
    font-weight: 300;
    margin-top: 5px;
    div {
      margin-left: 4px;
      float: right;
    }
    p {
      margin-top: 5px;
      font-size: 0.75em;
      text-align: right;
    }
  }

  .recipe-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .thumbnail {
    padding: 10px;
    flex: 1 1 200px;
    background-color: #eeeeee;
    margin: 5px;
    border-radius: 8px;
    max-height: 175px;
    text-decoration: none;
    border: 2px solid #eeeeee;
    &:hover {
      border: 2px solid #f4a80f;
      text-decoration-line: underline;
    }
  }

  .thumbnail-image {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 125px;
    object-fit: cover;
    object-position: center;
    border-radius: 6px 6px 0px 0px;
    background-color: #cccccc;
    img {
      width: 75%;
      object-fit: scale-down;
      object-position: center;
    }
  }

  .thumbnail-name {
    width: 100%;
    word-wrap: normal;
    padding: 5px 0px;
    font-size: 13px;
  }

  .thumbnail-rating {
    align-self: flex-end;
  }

  .subheader {
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .review-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .review {
    h3 {
      font-weight: 400;
      font-size: 16px;
    }
    .comment {
      .text {
        width: 100%;
        border: $border;
        outline: none;
        resize: vertical;
        padding: 5px;
        min-height: 120px;
        font-family: $font;
      }
    }
    .update {
      width: 100px;
      float: right;
    }
  }
}

.create-recipe {
  max-width: 1120px;
  margin: 20px auto;
  padding: 20px;

  .delete {
    font-size: 25px;
    align-self: center;
    &:hover {
      cursor: pointer;
    }
  }

  .submit {
    margin: -50px 0px 30px 0px;
    width: 200px;
    float: right;
  }

  .step {
    position: relative;
    align-self: center;
    margin-right: 15px;
    bottom: 0;
    right: 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    border-radius: 50%;
    border: 2px solid $brand-border;
  }

  .wrapper {
    margin-top: 50px;

    button.btn {
      width: 100%;
      margin: 10px 0px;
      @media (min-width: 1000px) {
        width: auto;
        min-width: 160px;
        min-height: 63px;
        margin: 0px;
        &.spacer {
          margin: 0px 15px;
        }
      }
    }
  }

  h1 {
    margin: 0px 0px 15px 0px;
    font-weight: 100;
    color: $brand-primary;
  }

  h2 {
    font-family: 'Roboto' sans-serif;
    padding: 20px;
    background: $brand-primary;
    color: $white;
    font-weight: 100;
  }
  .section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 15px;

    button.btn {
      width: 100%;
      margin: 10px 0px;
      @media (min-width: 1000px) {
        width: auto;
        min-width: 160px;
        min-height: 63px;
        margin: 0px;
        &.spacer {
          margin: 0px 15px;
        }
      }
    }

    .lt-form-element {
      width: 100%;
      margin-right: 15px;
      padding-bottom: 0px;
      flex: 1 1 200px;
    }
  }
}
