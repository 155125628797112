.allergens {
  > div:nth-child(even) {
    background: $light-gray;
  }
  ul {
    margin: 0px;
    padding: 5px 0px 5px 40px;
    li {
      display: inline-block;
      list-style-type: none;
      padding-right: 15px;
      text-transform: capitalize;
      line-height: 1.4;
      &:after {
        content: ","
      }
      &:last-child:after {
        content: ""
      }
    }
  }
  /* Responsive
  ==================================== */
  @media all and (max-width: 900px) {
    .table--collapse  {
      margin-bottom: 5px;
      display: block;
      > .table-cell {
        min-width: 100% !important;
        margin: 15px 0px;
      }
      > .table-cell--foot { margin-bottom: 1em; }
    }
  }
  .table {
    display: flex;
    margin-bottom: 1px;
     > div {
    display: flex;
    align-items: center;
    justify-content: center;
    }
    @media (max-width: 900px){
      flex-wrap: wrap;
      > div {
     display: block;
     }
    }
  }
  .table-cell--head {
    flex: 1;
    max-width: 230px;
    min-width: 230px;
    @media (max-width: 900px){
      min-width: 100%;
    }
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }

  // Allergen Colors
  .mild {
    background-color: #F9C80E;
    color: $white
  }
  .moderate {
    background-color: #E89005;
    color: $white;
  }
  .severe {
    background-color: #CB0000;
    color: $white;
  }
  .dislike {
    background-color: #272838;
    color: $white;
  }
  .countdown {
    align-self: center;
    font-size: 10px;
    padding: 4px;
    border-radius: 3px;
    margin: auto;
  }
  button.icon {
    font-size: 13px;
    padding: 0px;
  }
}

.edit-allergens {
  float: right;
  margin-right: 40px;
  color: $black;
  font-size: 24px;
  margin-top: 20px;
}

.family {
  .profile-header {
    .family-members {
      margin-top: 15px;
    }
  }
  .dislikes,
  .allergens-wrapper {
    padding: 0px 25px;
  }
  .dislikes {
    margin-bottom: 25px;
  }
}


.allergens-template {
  flex: 1 1 500px;
  margin: 15px;
  position: relative;
  @keyframes spinner-fade {
    0% {
      background-color: $black;
    }
  }
  .dislikes {
    .table-cell--head {
        display: none;
    }
    ul {
      padding: 0px;
    }
    .allergens {
      @media all and (min-width: 900px) {
        display: flex;
      }
    }
    .add-dislike {
      width: 100%;
      max-width: 230px;
      padding-right: 40px;
      .lt-form-element {
        padding-bottom: 0px;
      }
      label {
        display: none;
      }
    }
    .table,
    > div:nth-child(even) {
      background: none;
    }
  }
}
.allergens-header {
  display: flex;
  justify-content: space-between;
  margin: 0;
  text-align: center;
  font-family: 'Roboto' sans-serif;

  h2 {
    display: flex;
    margin-left: 0px;
    padding: 20px 0px !important;
  }

  button, a {
    padding: 0px;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    border: 1px solid $brand-border;
    color: $black;
    text-decoration: none;
    border-radius: 4px;
    font-size: 24px;
    border: none;
    background: none;
    cursor: pointer;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .spinner {
    margin-top: auto;
    margin-bottom: auto;
  }
  .spinner-holder {
    margin-right: 25px;
  }
}
