html,body{
  padding: 0px;
  margin: 0px;
}
body{
  font-family: $font;
}
.dashboard-container {
  background: #fafafa;
}
.clickable {
  &:hover {
    cursor: pointer;
  }
}
.link {
  a {
    color: $brand-primary;
    background-color: $light-gray;
    font-size: 18px;
    border-radius: 5px;
    padding: 12px;
    text-decoration: none;
    &:hover {
      color: $black;
    }
  }
}
.alert{
  padding: 12px 20px;
  border-radius: .25rem;
  &.small {
    padding: 6px 10px;
    font-size: 0.75em;
    .exclamation {
      font-size: 0.8em;
      padding: 1px;
      margin: 0px 3px;
    }
  }
  &.alert-warning {
    color: $brand-warning;
    background-color: #f0f0ca;
    border-color: #f5c6cb;
  }
  &.alert-danger {
    color: $brand-danger;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  .alert__link {
    color: $brand-primary;
    text-decoration: none;
    &:hover {
      color: $black;
    }
  }
 }
