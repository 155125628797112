.lt-btn {
  position: relative;
  font-size: 24px;
  line-height: 24px;
  padding: 18px $padding-default;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: .5;
    cursor: no-drop;
    &:hover {
      opacity: .5;
    }
  }

  &.running {
    padding: 31px $padding-default;
    &.small {
      padding: 20px 50px;
    }
  }

  &.small {
    font-size: 16px;
    line-height: 1.2;
  }
  &.full-width{
    width: 100%;
  }
  &.right{
    float: right;
  }
  &:focus {
    outline: none;
  }
}

.lt-btn-blue {
  background: $brand-primary;
  color: $white;

  &:hover {
    color: $white;
    opacity: .8;
  }
}

.lt-btn-red {
  background: #B22222;
  color: $white;

  &:hover {
    color: $white;
    opacity: .8;
  }
}


.lt-btn-outline {
  box-sizing: border-box;
  color: $black;
  background: transparent;
  border: 2px solid $brand-border;

  &:hover {
    color: $text-color;
  }
}


.btn-help {
  background: none;
  border: none;
}

.btn.close {
  background: transparent;
  border: none;
  float: right;
  padding: 0px;
  position: relative;
  top: -4px;
  .close-icon{
    display: inline-block;
    cursor: pointer;
  }
  .close-icon > div{
    width: 25px;
    background-color: $black;
  }
  &:hover{
    .close-icon > div{
      background-color: $brand-primary;
    }
  }
  &.white{
    .close-icon > div{
      background-color: $white;
    }
    &:hover{
      .close-icon > div{
        background-color: $white;
      }
    }
  }
}

button {
  &.icon {
    border: none;
    background: none;
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
    &:focus{
      outline: none;
    }
  }
}
