.setup-wizard {
    max-width: 1100px;
    margin: 20px auto;
    padding: 0px 10px 10px;
    border: 1px solid $brand-border;
    .title{
      display: flex;
      justify-content: space-between;
      position: relative;
      background: $brand-primary;
      color: $white;
      font-size: 28px;
      padding: 15px 15px 15px 20px;
      font-weight: 100;
      margin: 0px -10px 40px;
      align-items: center;
      @media (max-width: 992px) {
        display: block;
        .nav {
          margin-top: 15px;
          max-width: 300px;
          .dot-wrapper {
            margin: 7px 0px;
          }
        }
      }
    }
    .nav {
      display: flex;
      flex-wrap: wrap;
      .dot-wrapper {
        font-size: 14px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        &:last-child {
          display: none;
        }
        .label {
          margin: 0px 20px 0px 5px;
        }
      }
    }
    .button-nav{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .lt-btn.small {
        margin-left: 15px;
      }
      .button-nav-inner {
        padding: 0px 10px 10px;
      }
    }
    .active {
      color: $white;
      opacity: 1;
      .step-number {
        color: $brand-secondary;
        font-weight: 400;
      }
    }
    .step-wrapper{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .column.fifty{
        width: 50%;
      }
      .full-width{
        width: 100%;
      }
    }
    .summary {
      padding: 0px 15px 15px;
      h2 {
        margin-top: 0px;
      }
    }
    .iagree{
        margin: 10px;
        margin-bottom: 30px;
        background: #f4f4f4;
        padding: 15px 30px 30px;
        font-size: .8em;
        max-height: 450px;
        overflow: scroll;
        ol {
          margin-bottom: 15px;
        }
        li {
          margin-bottom: 5px;
        }
        .radio,
        .checkbox{
          margin: 15px 0px 0px auto;
          width: fit-content;
          label {
            cursor: pointer;
          }
        }
        &.transitional-program {
          .radio,
          .checkbox{
            margin: 15px 0px;
          }
        }
    }
    .lt-form-element {
      margin: 0px 10px;
    }
    h2, p {
      margin-bottom: 15px;
    }
}
