.login-container {
  background: url('../images/login-bg.jpg');
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.register-sidebar,
.login-sidebar {
  background: $login-background;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: $padding-default;
  text-align: center;
  overflow: scroll;

  max-width: 833px;
  width: 100%;
  box-sizing: border-box;

  h1 {
    font-size: 46px;
    color: $black;
    font-family: $font;
    font-weight: 300;
    margin-top: $padding-default * 2;
    margin-bottom: $padding-default * 3;
  }
  img {
    padding-top: 100px;
    max-width: 100%;
  }
  .bottom-link {
    font-size: 20px;
    color: $black;
    font-family: $font;
    font-weight: 300;
    margin-top: $padding-default * 2;
    margin-bottom: $padding-default * 3;
    text-decoration: none;
    &:hover {
      color: $brand-primary;
      cursor: pointer;
    }
  }
  .info {
    font-size: 30px;
    color: $black;
    font-family: $font;
    font-weight: 300;
    margin-top: $padding-default * 2;
    margin-bottom: $padding-default * 3;
  }
}


.register-form-wrapper,
.login-form-wrapper {
  max-width: 584px;
  width: 100%;
  margin: auto;
  position: relative;
}


.login-help {
  width: 100%;
  text-align: right;
  padding-bottom: ($padding-default + 5);
}
