.main-spinner {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
}
.dbl-spinner {
position: absolute;
width: 75px;
height: 75px;
border-radius: 50%;
background-color: transparent;
border: 4px solid transparent;
border-top: 4px solid $brand-primary;
border-left: 4px solid $brand-primary;
-webkit-animation: 2s spin linear infinite;
animation: 2s spin linear infinite;
}

.dbl-spinner:nth-child(2) {
border: 4px solid transparent;
border-right: 4px solid $brand-secondary;
border-bottom: 4px solid $brand-secondary;
-webkit-animation: 1s spin linear infinite;
animation: 1s spin linear infinite;
}

@-webkit-keyframes spin {
from {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
to {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
}

@keyframes spin {
from {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
to {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
}
