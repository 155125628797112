.mealplans {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    h2 {
      margin: 0;
      text-align: center;
      font-family: 'Roboto' sans-serif;
      padding: 20px;
      background: $brand-primary;
      color: $white;
      font-weight: 100;
    }
  }

  .plans {
    width: 100%;
    max-width: 340px;
    border: 1px solid $brand-border;
    margin: 15px;
    .plans-inner{
      margin: 10px;
      .mealplan{
        margin: 5px 0px;
        &.active button{
          border: 2px solid $brand-primary;
        }
      }
    }
  }
  .plan {
    border: 1px solid $brand-border;
    flex: 1 1 500px;
    margin: 15px;
    .plan-inner{
      margin: 10px;
    }
  }
  .meal {
    border: 1px solid $brand-border;
    margin: 0px 15px;
    padding: 15px;
    flex: 1 1 200px;
    &:nth-child(2) {
      margin: 0 20px;
    }
    .add-recipes{
    font-size: 20px;
    margin-bottom: 5px;
      .add-more {
        border: none;
        float: right;
        background: none;
        font-size: 20px;
        line-height: 1;
        cursor: pointer;
        &:hover{
          color: $brand-primary;
        }
      }
    }
  }
  .mealtype {
    margin-top: 15px;
  }
}
